/**
 * @param {{
 *   AppConfigsService: ReturnType<import('../../services/AppConfigsService').default>,
 * }} param0
 */
export default function appConfigsUseCase({ AppConfigsService }) {
  const getAppConfigs = () => {
    console.log('>>> AppConfigsService', AppConfigsService);
    return AppConfigsService.getAppConfigs();
  };

  return {
    getAppConfigs: getAppConfigs,
  };
}
